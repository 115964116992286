import { useState } from "react";
import '../node_modules/font-awesome/css/font-awesome.min.css'


function App() {
  return (
    <>
      <Navbar />
      <Banner />
      <Welcome />
      <Services />
      <Footer/>
    </>
  );
}

function Navbar(){
  return (<div className="navbar bg-gray-950">
  <div className="container m-auto p-4">
    <h1 className="text-white text-left text-2xl pl-5 font-bold block">
      <img className="inline-block mr-3" src="/images/evesshub_circle.png" alt="EvessHub Logo" style={{ height: '1.5em', marginTop: '-5px'}} />
       <Logo /></h1>
  </div>
</div>)
}
 

const bannerStyle= {
  backgroundImage: `url('/images/bg-01.jpg')`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '70vh',
  minHeight: '600px'
}
function Banner(){
  return (
    <div className="">
      <div style={bannerStyle} className="relative flex flex-row justify-center pb-10">
        <div className="absolute inset-0 bg-black bg-opacity-50 backdrop-blur-xs"></div>
        <div className="relative z-10 my-auto py-10">
          <h2 className="text-center text-yellow-400 font-bold text-6xl"><Logo /></h2>
          <h2 className="text-center text-6xl leading-tight text-white p-4 font-bold">Crafting Unforgettable Moments</h2>
          <p className="p-8 text-center text-3xl p-5 text-white">Your One-Stop <span className="text-yellow-200">Hub</span> for <span className="text-yellow-200">Event Essentials</span></p>
        </div>
      </div>
    </div>
  )
}

function Welcome(){
  return (
    <>
    <div className="p-4 bg-gray-950 py-10">
      <div className="container m-auto">
        <h1 className="text-3xl text-center text-white pt-5">Welcome to <Logo /></h1>
        <p className="text-gray-200 text-xl text-center m-auto max-w-4xl mt-5 p-5 leading-9">Planning a memorable event just got easier. At <Logo />, we provide everything you need to create unforgettable experiences. Whether you’re organizing a wedding, corporate event, or a special celebration, our wide range of event essentials ensures that every detail is perfect.</p>
      </div>
    </div>
    </>
  )
}


const serviceItems = [
  {
    "title": "Web Invitations",
    "subtitle": "Effortless Web Invitations for Seamless Event Planning",
    "description": "Web Invitations service allows you to create beautifully designed, personalized event invitations with ease. Create in minutes and share your invitations digitally, ensuring your guests receive all the key details in a stylish and seamless format.",
    "details": [
      {
        "feature": "Quick and Simple Setup",
        "description": "With EvessHub, crafting your web invitations is a breeze. Our straightforward platform allows you to easily input event details and personalize your invitations without any design skills required."
      },
      {
        "feature": "Instant Digital Delivery",
        "description": "Save time and money by sending your invitations digitally. We ensure that your invites reach your guests instantly, eliminating the need for printing and mailing."
      },
      {
        "feature": "Eco-Friendly Choice",
        "description": "Opting for web invitations reduces paper waste and is a more sustainable choice. Enjoy a seamless event planning experience while contributing to a greener planet."
      }
    ]
  },
  {
    "title": "Guest List & RSVP Tracking",
    "subtitle":"Simplify the Planning Process",
    "description": "Keeping track of your guest list and RSVPs can be one of the most challenging aspects of wedding planning. Our Guest List & RSVP Tracking feature is designed to make this process effortless:",
    "details": [
      {
        "feature": "Streamline Your Guest List",
        "description": "Manage your guest list with ease. Add and organize your guests, track invitations, and keep everything up to date in real time."
      },
      {
        "feature": "Automated RSVP Management",
        "description": "Say goodbye to paper RSVPs and manual tracking. With our online RSVP system, your guests can respond quickly and easily, and their responses will be automatically updated in your dashboard."
      },
      {
        "feature": "Customizable RSVP Questions",
        "description": "Need to know meal preferences or song requests? Customize your RSVP forms to gather all the information you need from your guests."
      },
      {
        "feature": "Real-Time Updates",
        "description": "Stay on top of your planning with real-time updates and notifications as RSVPs come in. You’ll always know exactly how many guests to expect and can plan accordingly."
      }
    ]
  }
]

function Services(){
  const [ selectedService, setSelectedService] = useState(0)

  return <><div className="pt-10">
      <div className="container m-auto px-10 pt-10 pb-5">
        <h2 className="text-2xl font-semibold text-yellow-400 text-left">Elevate Your Wedding Planning</h2>
        <div className="grid grid-cols-2 my-10 gap-y-9 gap-x-5">
          <div className="col-span-2 lg:col-span-1">
            <img src="/images/landing-wed-website.png"  alt="Wedding invitation" className="rounded-md m-auto"></img>
          </div>
          <div className="col-span-2 lg:col-span-1 relative px-5">
            
            
            <p className="text-gray-800 leading-9 text-lg text-left max-w-xl mt-4">At <LogoLight className="text-orange-800"/>, we’re all about making your event planning journey seamless and stress-free. We’re excited to introduce two upcoming features that will take your wedding planning to the next level: the Web Invitation and our Guest List & RSVP Tracking. </p>

            <h2 className="mt-5 text-2xl font-semibold text-yellow-400">Designed to save your:</h2>
            
            <div className="flex justify-center lg:justify-start flex-row items-center">
              <MiniIcons faicon="💸" label="Money"></MiniIcons>
              <MiniIcons faicon="⏳" label="Time"></MiniIcons>
              <MiniIcons faicon="💪" label="Effort"></MiniIcons>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <div className="bg-gray-950 p-5 md:p-10">
      <div className="container m-auto">
        <div className="mt-4">
          <ServicesItemsNav selectedService={selectedService} setSelectedService={setSelectedService} />
        </div>
        <div className="grid grid-cols-2 my-10 gap-y-9 gap-x-5">
          { selectedService !== null && <ServicesItems serviceItem={serviceItems[selectedService]}/>}
        </div>
      </div>
    </div>
    </>
}


function MiniIcons({ faicon, label }){
  return <div className="w-20 md:w-36 aspect-square text-2xl md:text-5xl bg-gray-100 text-center rounded-md text-yellow-400 m-2 align-middle flex">
    <div className="m-auto">
      <span className="block">
        { faicon }
      </span>
      <p className="text-lg md:text-2xl text-black">{label}</p>
    </div>
  </div>
}

function ServicesItemsNav({selectedService,setSelectedService}){
  return <>{ serviceItems.map((i,idx)=> <><button className={`py-2 px-4 text-lg rounded-md w-full md:w-auto ${selectedService === idx ? 'bg-yellow-400' : 'bg-yellow-100'}`} onClick={e=>{
    setSelectedService( s => idx);
  }}>{i.title} 
  &nbsp;
  <span className="block rounded-md px-2 py-1 rounded-lg text-yellow-200 font-semibold  bg-black text-xs">Coming soon!</span></button> &nbsp;
    </>
  )}</>
}

function ServicesItems({ serviceItem }){
  return <div className="col-span-2 bg-gray-100 rounded-md p-9">
      <h2 className="text-left text-2xl font-semibold mb-5">{serviceItem.title}: {serviceItem.subtitle}</h2>
      <p className="text-lg leading-7 mb-4">{serviceItem.description}</p>
      <ul className="block pr-5">
        {
          serviceItem.details.map(c => {
            return <li>
            <h3 className="font-bold text-orange-500 ">{c.feature}: </h3>
            <p className="text-lg leading-7 mb-4">{ c.description }</p>
          </li>
          })
        }
      </ul>
    </div>
}


function Footer(){
  return (
  <div className='bg-gray-800 normal-font' style={{height: '60px'}}>
    <div className='container m-auto p-2'>
      <p className='text-gray-300 text-sm text-center normal-font'>
      <span className='text-xs normal-font'>EvessHub &bull; All rights reserved { new Date().getFullYear()}</span>
      <br/>
        A product of <a className='text-green-400 hover:text-green-500 normal-font' href='https://bitslytech.com'>Bitsly I.T. Solutions.</a> 
      </p>
    </div>
  </div>)
}


function Logo({className=""}){
  return <span className={`text-yellow-400 font-bold ${className}`}>
    EvessHub
  </span>
}

function LogoLight({className="text-yellow-400"}){
  return <span className={`font-extralight ${className}`}>
    EvessHub
  </span>
}

export default App;
